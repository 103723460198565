@font-face {
  font-family: "iconfont";
  /* Project id 4550316 */
  src: url('//at.alicdn.com/t/c/font_4550316_v0xmuj5hha.woff2?t=1723396201787') format('woff2'),
    url('//at.alicdn.com/t/c/font_4550316_v0xmuj5hha.woff?t=1723396201787') format('woff'),
    url('//at.alicdn.com/t/c/font_4550316_v0xmuj5hha.ttf?t=1723396201787') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;

}

.icon-bitcoin:before {
  content: "\e8cf";
}

.icon-Cardano:before {
  content: "\e612";
}

.icon-logo-binance:before {
  content: "\e614";
}

.icon-a-16_Lined_ripple:before {
  content: "\e730";
}

.icon-ethereum:before {
  content: "\ebfb";
}

.icon-a-solana-sol:before {
  content: "\e956";
}

.icon-discord:before {
  content: "\f18d";
}

.icon-instagram:before {
  content: "\e6fc";
}

.icon-Linkedin:before {
  content: "\e6b9";
}

.icon-tuite:before {
  content: "\ee49";
}

.icon-right:before {
  content: "\e93e";
}

.icon-menu:before {
  content: "\e7f4";
}

.icon-chrome-fill:before {
  content: "\e884";
}

.icon-apple:before {
  content: "\e617";
}

.icon-android:before {
  content: "\e69f";
}

.icon-search:before {
  content: "\e7c9";
}

.icon-down:before {
  content: "\e839";
}