@font-face {
  font-family: SpaceGroteskVariableFontWght;
  src: url('/public/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
}

* {
  font-family: SpaceGroteskVariableFontWght, sans-serif !important;
  padding: 0;
  margin: 0;
  line-height: 1;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-title-text-gradient {
  /* background: rgb(206,149,110);; */
  background-image: linear-gradient(90deg, rgba(206, 149, 110, 0.11) 0%, rgba(206, 149, 110, 1) 54%, rgba(206, 149, 110, 0) 100%);
  -webkit-background-clip: text;
  color: transparent;
  /* text-shadow: 0px 3px 0px rgba(0, 0, 0, 1); */
}

.line-height-1-2 {
  line-height: 1.2;
}

.line-height-point-149-2 {
  line-height: 149.2%;
}

.line-height-numnber-69 {
  line-height: 30px;
}

.text-shadow {
  text-shadow: 0px 2px 6px rgba(0, 0, 0, .7);
}

.letter-spacing-point-134 {
  letter-spacing: 134%;
}